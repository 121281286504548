import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Moment from 'moment';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { useAuthContext } from '../hooks/useAuthContext';
import { useReview } from '../hooks/useReview';

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const SingleReview = () => {

	const { user } = useAuthContext();
	const { propertyID }  = useParams();

	const [propertyData, setPropertyData] = useState([]);
	const [reviewData, setReviewData] = useState([]);
  	const [userData, setUserData] = useState([]);

	const [rating, setRating] = useState(0);
	const [comment, setComment] = useState('');

	// 13th May 2024 12AM GMT+8
	const startTimestamp = 1715529600000;
	const [runningDays, setRunningDays] = useState(0);
	const calcRunningDays = () => {
		const days = (Date.now()-startTimestamp)/86400000;
		setRunningDays(parseInt(days));
	};

	const fetchPropertyData = async () => {
		const response = await fetch('https://api.corpagent.global/api/property/' + propertyID, {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		setPropertyData(json);
	};
	
	const fetchReviewsByPropertyID = async () => {
		const response = await fetch('https://api.corpagent.global/api/review/' + propertyID, {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		setReviewData(json);
	};

	const getUserData = async () => {
		const response = await fetch('https://api.corpagent.global/api/user/'+ user.username, {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();

		if (response.ok) {
			if (json.length === 1) {
				setUserData(json[0]);
				if (json[0].freezed) {
					localStorage.removeItem('corpagent_logged_in');
					window.location.href= "/";
				}
			}
		}
	};

	const [dailyTasks, setDailyTasks] = useState();
	const [popUpData, setPopUpData] = useState();
	const getDailyActiveTasks = async () => {
		const response = await fetch('https://api.corpagent.global/api/property/dailytask', {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			console.log("Active Tasks:", json);
			setDailyTasks(json);
			const fetchPopUp = await fetch('https://api.corpagent.global/api/property/' + json[10]._id, {
				headers: {
					'Authorization': `Bearer ${user.token}`
				}
			});
			const result = await fetchPopUp.json();
			setPopUpData(result);
		}
	}

	const [userTodayReview, setUserTodayReview] = useState();
	const getUserDailyReviews = async () => {
		const days = (Date.now()-startTimestamp)/86400000;
		const response = await fetch('https://api.corpagent.global/api/review/user/'+user.username+"/"+parseInt(days), {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setUserTodayReview(json);
		}
	}

	const {submitReview, error, isLoading} = useReview();
	const handleSubmit = async (e) => {
	  e.preventDefault();
	  console.log(userTodayReview.length);
	  await submitReview(user.token, userData._id, userData.cashWallet, userData.username, userData.status, propertyID, rating, comment, runningDays, userTodayReview.length, dailyTasks);
  	};

	const [loadDelay, setLoadDelay] = useState(false);
	useEffect(() => {
		if (user) {
			calcRunningDays();
			getDailyActiveTasks();
			getUserData();
			fetchPropertyData();
			fetchReviewsByPropertyID();
			getUserDailyReviews();
		}
		setTimeout(() => getUserData(), 1000);
		setTimeout(() => setLoadDelay(true), 2000);
	}, []);

  return (
    <>
		{/* Booking Order Pop Up Modal */}
		{/* <div className={userData && userData.status === "Incomplete Task" ? "modal fade d-block show" : "modal fade"} id="newOrderModal" tabindex="-1" role="dialog" aria-labelledby="newBookingOrder" aria-hidden="true"> */}
		<div className="modal fade" id="newOrderModal" tabindex="-1" role="dialog" aria-labelledby="newBookingOrder" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-body">

						<div className="page-container text-center">
							<span className="alert-icon"><i className="fa-sharp fa-solid fa-bell"></i></span>
							<h5 className="">New Booking Order:</h5>
							<img className="w-100" src={popUpData && popUpData.coverImgLink}></img>
							<h5 className="mt-2 mb-0">{popUpData && popUpData.title}</h5>
							<div>
								<span className="review-stars"><i className="fa-solid fa-star"></i></span>
								<span className="review-stars"><i className="fa-solid fa-star"></i></span>
								<span className="review-stars"><i className="fa-solid fa-star"></i></span>
								<span className="review-stars"><i className="fa-solid fa-star"></i></span>
								<span className="review-stars"><i className="fa-solid fa-star"></i></span>
							</div>
							<button className="btn action-button mt-3" onClick={(e) => {
								window.location.href = "/property/"+dailyTasks[10]._id;
							}}>
							<p className="mb-0"><strong>Bid Order Now</strong></p>
							</button>
						</div>

					</div>
				</div>
			</div>
		</div>

		{!loadDelay ? (
			<div className="container">
				<div className="row">
					<div className="col-12 text-center my-5">
						Loading Next Property Review...
					</div>
				</div>
			</div>
		):(
			<div className="container">
				<div className="row">
					<div className="col-12 p-0">
						<Swiper
						loop
						grabCursor={true}
						slidesPerView={1}
						speed={1000}
						pagination={true}
						autoplay={{
							delay: 4000,
							disableOnInteraction: false,
						}}
						>
						<SwiperSlide className="text-center"><img className="hotel-slider-img" src={propertyData.coverImgLink} alt="Homepage Banner"></img></SwiperSlide>
						</Swiper>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-12 px-2 mt-2">
						<div className="page-container">
							<h3 className="mt-3 mb-2">{propertyData.title}</h3>
							<p className="">
								<span className="location-icon mr-2"><i className="fa-solid fa-location-dot"></i></span>
								{propertyData.address}
							</p>
							<hr></hr>
							<h3 className="blue mt-3 mb-0">{propertyData.star}.0 Rating</h3>
							<div>
								<span className={propertyData.star >= 1 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={propertyData.star >= 2 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={propertyData.star >= 3 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={propertyData.star >= 4 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={propertyData.star >= 5 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
							</div>
							<div className="row mb-3">
								<div className="col-12 mt-2">
									{userTodayReview && userTodayReview.length < 15 ? (
										<>
										<div className="page-container mt-3">
											{userData && userData.status === "Incomplete Task" || userData && userData.status === "Disabled" ? (
												<>
													{userData.status === "Incomplete Task" ? <div className="error-box py-2"><p className="mb-0">Booking order not yet complete. Please contact customer support.</p></div> : <></>}
													{userData.status === "Disabled" ? <div className="error-box py-2"><p className="mb-0">Review is not available. Please contact customer support.</p></div> : <></>}
												</>
											):(
												<form onSubmit={handleSubmit}>
													<h5 className="mt-3 mb-0 ml-2">Post a review:</h5>
													<div class="rate">
														<input type="radio" id="star5" name="rate" value="5" onChange={(e) => setRating(5)}/>
														<label for="star5" title="text">5 stars</label>
														<input type="radio" id="star4" name="rate" value="4" onChange={(e) => setRating(4)}/>
														<label for="star4" title="text">4 stars</label>
														<input type="radio" id="star3" name="rate" value="3" onChange={(e) => setRating(3)}/>
														<label for="star3" title="text">3 stars</label>
														<input type="radio" id="star2" name="rate" value="2" onChange={(e) => setRating(2)}/>
														<label for="star2" title="text">2 stars</label>
														<input type="radio" id="star1" name="rate" value="1" onChange={(e) => setRating(1)}/>
														<label for="star1" title="text">1 star</label>
													</div>
													<textarea
														name='review'
														className='w-100 py-3 mt-3 review-text-field'
														placeholder='Write your review here...'
														onChange={(e) => setComment(e.target.value)}
													/>
													<button  disabled={isLoading} type="submit" className="btn action-button unselected-btn mt-2 w-100">
														<p className="mb-0">Submit</p>
													</button>
													{error && <div className="error">{error}</div>}
												</form>
											)}

										</div>
										</>
									):(
										<div className="error-box py-2"><p className="mb-0">Daily review limit reached ({userTodayReview} out of 15).</p></div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 mt-5">
						{reviewData && reviewData.length === 0 ? (
							<>
								<h6 className="mb-0">No review yet for this property...</h6>
								<p className="mb-0">Be the first one to post</p>
							</>
						):(
							<>
								<h6 className="mb-0">Total {reviewData.length} Reviews</h6>
							</>
						)}
					</div>
					{reviewData && reviewData.map((review, index) => (
						<div className="col-12 px-2" key={index}>
							<div className="page-container mt-3">
								<h6 className="mb-0">{reviewData[index].username}</h6>
								<div>
									<span className={reviewData[index].rating >= 1 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
									<span className={reviewData[index].rating >= 2 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
									<span className={reviewData[index].rating >= 3 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
									<span className={reviewData[index].rating >= 4 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
									<span className={reviewData[index].rating >= 5 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
								</div>
								<p className="mb-0">{reviewData[index].comment}</p>
								<p className="timestamp-text mt-3 mb-0">Posted: {Moment(reviewData[index].createdAt).format('YYYY-MM-DD HH:mm')}</p>
							</div>
						</div>
					))}
				</div>
						
			</div>
		)}

    </>
  );

};

export default SingleReview;