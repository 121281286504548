import React, { useEffect, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Moment from 'moment';

import { useAuthContext } from '../hooks/useAuthContext';
import { useDeposit } from '../hooks/useDeposit';

import TronQR from '../assets/img/corpagent-tron-qr.jpg';
import BEP20QR from '../assets/img/bep20.jpg';
import USDT from '../assets/img/tether-usdt-logo.png';

const Deposit = () => {

	const { user } = useAuthContext();
	const [userData, setUserData] = useState();
	const [amount, setAmount] = useState(0);
	const [selectedDeposit, setSelectedDeposit] = useState();

	const [selectedChain, setSelectedChain] = useState("TRON");

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyAddress = () => {    
	  setCopied(true);
	  setCopyText("Copied");
	  setTimeout(() => setCopied(false), 3000);
	}

	const { submitDeposit, isLoading, error } = useDeposit();
	const [submitted, setSubmitted] = useState(false);

	const handleDeposit = async (e) => {
		e.preventDefault();
		if (amount <= 0) {
			return;
		}
		await submitDeposit(user.token, user.username, amount);
		fetchDepositData();
		getUserData();
		setSubmitted(true);
	};

	const [depositData, setDepositData] = useState();
	const fetchDepositData = async () => {
		const response = await fetch('https://api.corpagent.global/api/deposit/user/' + user.username, {
			headers: {
			'Authorization': `Bearer ${user.token}`
			}
		});
		const depositRecords = await response.json();
		setDepositData(depositRecords);
	}

	const getUserData = async () => {
		const response = await fetch('https://api.corpagent.global/api/user/'+ user.username, {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				console.log(json[0]);
				setUserData(json[0]);
				if (json[0].freezed) {
					localStorage.removeItem('corpagent_logged_in');
					window.location.href= "/";
				}
			} else {
				console.log("Invalid user data: More than 1 result.")
			}
		}
	}

	useEffect(() => {

		if (user) {
			getUserData();
			fetchDepositData();
		}

	}, []);

	return (
		<>
			{userData ? (
				<>
					{/* Deposit Modal */}
					<div className="modal fade" id="depositModal" tabindex="-1" role="dialog" aria-labelledby="depositModal" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLongTitle">Deposit</h5>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
									</button>
								</div>
								{!submitted ? (
									<div className="modal-body">
										<form className="w-100" onSubmit={handleDeposit}>	
											<div className="mb-2">
												<label htmlFor="amount">
													<strong>Deposit Amount:</strong>
												</label>
												<input
													type="number"
													step="0.01"
													placeholder='Enter Amount'
													autoComplete='off'
													name='amount'
													className='form-control'
													onChange={(e) => setAmount(e.target.value)}
												/>
											</div>
											<button disabled={isLoading || amount <= 0} type="submit" className="btn action-button w-100 mt-2">
												<p className="text-white mb-0">Confirm Deposit</p>
											</button>
											{error && <div className="error">{error}</div>}
										</form>
									</div>
								):(
									<div className="modal-body">
										<div className="text-center pb-3">
											<p className="attention-box mb-0 p-3" style={{fontSize:"14px"}}>
												Please transfer the exact deposit amount: {parseFloat(amount).toFixed(2)} USDT and send the proof of transfer to customer support after transaction is completed.
											</p>
											<h3 className="mt-3">
												Amount:
												<br></br>
												<img className="mr-2" src={USDT} style={{width:"25px"}}></img>
												<span className="profit-green">{parseFloat(amount).toFixed(2)} USDT</span>
											</h3>
											<img className="w-50" src={TronQR}></img>
											<h6 className="mt-3">Transfer Address - TRON (TRC20):</h6>
											<p className="mb-0">TBExEkF3d5X8KgLVmzEnuV5nGp9SqqmYv4</p>
											<CopyToClipboard 
												text={"TBExEkF3d5X8KgLVmzEnuV5nGp9SqqmYv4"}
												onCopy={() => copyAddress()}>
												<button className="btn action-button mt-2">
													{!copied ? (
														<p className="mb-0"><i className="fa-solid fa-copy mr-2"></i>Copy Address</p>
													):(
														<p className="mb-0">{copyText}</p>
													)}
												</button>
											</CopyToClipboard>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Deposit Modal */}
					<div className="modal fade" id="depositDetailsModal" tabindex="-1" role="dialog" aria-labelledby="depositDetailsModal" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLongTitle">Deposit Details</h5>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
									</button>
								</div>
								{selectedDeposit && selectedDeposit.amount > 0 ? (
									<div className="modal-body">
										{selectedDeposit.status == "Processing" ? (
											<div className="text-center pb-3">
												<p className="attention-box mb-0 p-3" style={{fontSize:"14px"}}>
													Please transfer the exact deposit amount: {parseFloat(selectedDeposit.amount).toFixed(2)} USDT and send the proof of transfer to customer support after transaction is completed.
												</p>
												<h3 className="mt-3">
													Amount:
													<br></br>
													<img className="mr-2" src={USDT} style={{width:"25px"}}></img>
													<span className="profit-green">{parseFloat(selectedDeposit.amount).toFixed(2)} USDT</span>
												</h3>
												<h3 className="mt-3">Choose Network:</h3>
												<select 
												name='gateway'
												className='form-control mb-3'
												onChange={(e) => setSelectedChain(e.target.value)}
												>
													<option value="TRON">TRON (TRC20)</option>
													<option value="BSC">Binance Smart Chain (BEP20)</option>
												</select>
												{selectedChain == "TRON" ? (
													<div className="w-100">
														<img className="w-50" src={TronQR}></img>
														<h6 className="mt-3">Transfer Address - TRON (TRC20):</h6>
														<p className="mb-0">TBExEkF3d5X8KgLVmzEnuV5nGp9SqqmYv4</p>
														<CopyToClipboard 
															text={"TBExEkF3d5X8KgLVmzEnuV5nGp9SqqmYv4"}
															onCopy={() => copyAddress()}>
															<button className="btn action-button mt-2">
																{!copied ? (
																	<p className="mb-0"><i className="fa-solid fa-copy mr-2"></i>Copy Address</p>
																):(
																	<p className="mb-0">{copyText}</p>
																)}
															</button>
														</CopyToClipboard>
													</div>
												):(
													<></>
												)}
												{selectedChain == "BSC" ? (
													<div className="w-100">
														<img className="w-50" src={BEP20QR}></img>
														<h6 className="mt-3">Transfer Address - BSC (BEP20):</h6>
														<p className="mb-0">0x5c2Aac625475d5b29a449Ae7923bb5440d0a8131</p>
														<CopyToClipboard 
															text={"0x5c2Aac625475d5b29a449Ae7923bb5440d0a8131"}
															onCopy={() => copyAddress()}>
															<button className="btn action-button mt-2">
																{!copied ? (
																	<p className="mb-0"><i className="fa-solid fa-copy mr-2"></i>Copy Address</p>
																):(
																	<p className="mb-0">{copyText}</p>
																)}
															</button>
														</CopyToClipboard>
													</div>
												):(
													<></>
												)}
											</div>
										):(
											<></>
										)}
										{selectedDeposit.status == "Completed" ? (
											<div className="text-center pb-3">
												<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
												<h5 className="">Deposit Successful</h5>
												<h5 className="profit-green">+{parseFloat(selectedDeposit.amount).toFixed(2)} USDT</h5>
											</div>
										):(
											<></>
										)}
									</div>
								):(
									<></>
								)}

							</div>
						</div>
					</div>


					<div className="container">
						<div className="row mt-3 mb-5">
							<div className="col-12">
								<h3 className="mb-0">Deposit</h3>
							</div>
							<div className="col-12 mt-2">
								<div className="data-box d-flex justify-contain-left">
									<div className="data-icon-box bg-dark-green">
										<span className="data-box-icon text-gray">
											<i className="fa-solid fa-wallet"></i>
										</span>
									</div>
									<div className="data-text-box">
										<h6 className="data-box-heading mb-0">Cash Wallet</h6>
										<p className="data-box-number profit-green mb-0">${parseFloat(userData.cashWallet).toFixed(2)} <span className="text-dark"></span></p>
									</div>
								</div>

								<button className="btn action-button unselected-btn mt-2 w-100" data-toggle="modal" data-target="#depositModal" onClick={(e) => {
									setSubmitted(false);
								}}>
									<p className="mb-0">Deposit</p>
								</button>

							</div>
						</div>

						{/* Deposit Records */}
						<div className="row mt-3 mb-5">
							<div className="col-12">
								<h3 className="mb-0">Deposit History</h3>
							</div>
							<div className="col-12 mt-3">
								{depositData && depositData.length > 0 ? (
									<table className="table table-hover table-striped">
										<thead className="thead-dark">
											<tr className="">
												<th className="p-3" scope="col"><p className="mb-0">Deposit Details</p></th>
											</tr>
										</thead>
										<tbody>
										{depositData && depositData.map((deposit, index) => (
											<tr key={index}>
												<td className="px-3 py-2">
													<p className="mb-0"><strong>Transaction ID:</strong> #{deposit._id}</p>
													<p className="mb-0"><strong>Amount: </strong>${parseFloat(deposit.amount).toFixed(2)} USDT</p>
													{deposit.status === "Processing" ? <p className="mb-0"><strong>Status: <span className="vip-yellow">{deposit.status}</span></strong></p> : null}
													{deposit.status === "Completed" ? <p className="mb-0"><strong>Status: <span className="profit-green">{deposit.status}</span></strong></p> : null}
													{deposit.status === "Rejected" ? <p className="mb-0"><strong>Status: <span className="error">Payment Failed</span></strong></p> : null}
													<p className="mb-0"><strong>Date & Time: </strong>{Moment(deposit.createdAt).format('YYYY-MM-DD HH:mm')}</p>
													<p className="mb-0"><strong>Remarks: </strong><br></br>{deposit.remarks}</p>
													{deposit.status === "Rejected" ? (
														<></>
													):(
														<button className="btn action-button mt-2 mb-3" data-toggle="modal" data-target="#depositDetailsModal" onClick={(e) => {
															setSelectedDeposit(deposit);
														}}>
															<p className="text-white mb-0">View Details</p>
														</button>
													)}
												</td>
											</tr>
										))}
										</tbody>
									</table>
								):(
									<p className="">No deposit history found.</p>
								)}
							</div>
						</div>
					</div>
				</>
			):(
				<></>
			)}
		</>
	);

};

export default Deposit;