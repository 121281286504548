import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLogin } from '../hooks/useLogin';

import LogoTextBlack from '../assets/img/logotext-black.png';

const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const {login, error, isLoading} = useLogin();

	const handleSubmit = async (e) => {
		e.preventDefault();
		await login(username, password);
	};

  return (
		<div className="full-page">
			<div className="container login-page form-page-padding">
				<div className="row bg-white px-2 px-md-3 py-5 rounded">
					<div className="col-12">
						<img className="form-logo mb-3" src={LogoTextBlack} alt="logo"></img>
						<form className="w-100" onSubmit={handleSubmit}>
							<div className="mb-2">
								<label htmlFor="email">
									<strong>Username</strong>
								</label>
								<input
									type="text"
									placeholder='Enter Username'
									autoComplete='off'
									name='email'
									className='form-control rounded-0'
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="email">
									<strong>Password</strong>
								</label>
								<input
									type="password"
									placeholder='Enter Password'
									name='password'
									className='form-control rounded-0'
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn action-button mt-3 w-100">
								<p className="text-white mb-0">Login</p>
							</button>
							{error && <div className="error">{error}</div>}
						</form>
						<p className="mt-3 mb-0">Don't have an account?</p>
						<Link to="/signup" className="btn secondary-btn w-100">
							<p className="mb-0"><strong>Sign Up</strong></p>
						</Link>
					</div>
				</div>
			</div>
		</div>
  );

};

export default Login;