import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';


const AllReviews = () => {

	// 13th May 2024 12AM GMT+8
	const startTimestamp = 1715529600000;

  	const { user } = useAuthContext();

	const [userReviews, setUserReviews] = useState();
	const getAllUserReviews = async () => {
		const response = await fetch('https://api.corpagent.global/api/review/user/'+ user.username, {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setUserReviews(json);
		}
	}

	const [dailyTasks, setDailyTasks] = useState();
	const getDailyActiveTasks = async () => {
		const response = await fetch('https://api.corpagent.global/api/property/dailytask', {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			console.log("Active Tasks:", json);
			setDailyTasks(json);
		}
	}

	const [propertyData, setPropertyData] = useState();
	const [userTodayReview, setUserTodayReview] = useState();
	const getUserDailyReviews = async () => {
		const days = (Date.now()-startTimestamp)/86400000;
		const response = await fetch('https://api.corpagent.global/api/review/user/'+user.username+"/"+parseInt(days), {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setUserTodayReview(json);
			// const propertyGet = await fetch('https://api.corpagent.global/api/property/'+dailyTasks[json.length]._id, {
			// 	headers: {
			// 		'Authorization': `Bearer ${user.token}`
			// 	}
			// });
			// const data = await propertyGet.json();
			// if (propertyGet.ok) {
			// 	setPropertyData(data);
			// }
		}
	}

  	useEffect(() => {
		if (user) {
			getDailyActiveTasks();
			getAllUserReviews();
			getUserDailyReviews();
		}
	}, []);

  return (
    <>
      	<div className="container">
			<div className="row mt-3 mb-5">
				<div className="col-12">
					<h3 className="mb-0">My Reviews</h3>
				</div>
				<div className="col-12 mt-2">
					<div className="data-box d-flex justify-contain-left">
						<div className="data-icon-box bg-yellow">
							<span className="data-box-icon text-gray">
								<i className="fa-solid fa-star"></i>
							</span>
						</div>
						<div className="data-text-box">
							<h6 className="data-box-heading mb-0">Total Reviewed</h6>
							<p className="data-box-number mb-0">{userReviews && userReviews.length}</p>
						</div>
					</div>
				</div>
				<div className="col-12 mt-2">
					<div className="data-box d-flex justify-contain-left">
						<div className="data-icon-box bg-dark-green">
							<span className="data-box-icon text-gray">
								<i className="fa-solid fa-ballot-check"></i>
							</span>
						</div>
						<div className="data-text-box">
							<h6 className="data-box-heading mb-0">Daily Review Tasks</h6>
							<p className="data-box-number mb-0">{userTodayReview && userTodayReview.length} of 15</p>
						</div>
					</div>
				</div>
			</div>

			<div className="row mt-3 mb-5">
				{userTodayReview && userTodayReview.length < 15 ? (
					<>
						<div className="col-12 mb-2">
							<h3 className="mb-0">Property Review #{userTodayReview.length+1}</h3>
						</div>
						{dailyTasks ? (
							<>
								<div className="col-sm-6 col-lg-12 px-2 mt-2">
									<div className="page-container">
										<div className="row">
											<div className="col-lg-6">
												<img className="w-100" src={dailyTasks[userTodayReview.length].coverImgLink} alt="Property Cover"></img>
											</div>
											<div className="col-lg-6 mt-2 mt-lg-0">
												<p className="blue mb-2">
													<span className="location-icon mr-2"><i className="fa-solid fa-location-dot"></i></span>
													<strong>{dailyTasks[userTodayReview.length].location}</strong>
												</p>
												<h3 className="listing-title mb-0">{dailyTasks[userTodayReview.length].title}</h3>
												<div>
													<span className="review-stars"><i className="fa-solid fa-star"></i></span>
													<span className="review-stars"><i className="fa-solid fa-star"></i></span>
													<span className="review-stars"><i className="fa-solid fa-star"></i></span>
													<span className="review-stars"><i className="fa-solid fa-star"></i></span>
													<span className="review-stars"><i className="fa-solid fa-star"></i></span>
												</div>
												<h4 className="booking-price profit-green mb-0">${parseFloat(dailyTasks[userTodayReview.length].price).toFixed(2)}</h4>
												<p className="mb-0">per night</p>
												<Link to={'/single-review/' + dailyTasks[userTodayReview.length]._id}>
													<button className="btn action-button unselected-btn mt-2">
													<p className="mb-0">Review Now</p>
													</button>
												</Link>
											</div>
										</div>
									</div>
								</div>
							</>
						):(
							null
						)}
					</>
				):(
					<>
						<div className="col-12 mb-2">
							<h3 className="mb-0">Thank you for review!</h3>
							<p className="mb-0">Return tomorrow for more review bonuses.</p>
							<div className="error-box py-2 mt-2"><p className="mb-0">Daily review limit reached ({userTodayReview && userTodayReview.length} out of 15).</p></div>
						</div>
					</>
				)}
			</div>
      	</div>
    </>
  );

};

export default AllReviews;