import { useState } from 'react';

export const useReview = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const submitReview = async (authToken, curUserId, curCashBal, username, userStatus, propertyId, rating, comment, dayCount, todayReviewCount, taskList) => {
        setIsLoading(true);
        setError(null);
        var earning = 5;
        if (rating < 5) {
            earning = 0;
        }
        if (todayReviewCount >= 15) {
            setError("Maximum review reached for today.");
            setIsLoading(false);
            window.location.href = "/reviews";
        }
        const response = await fetch('https://api.corpagent.global/api/review/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({username, propertyId, rating, comment, day: dayCount, earned: earning})
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {

            // if (rating === 5) {
            //     var newBal = curCashBal+5;
            // }
            // if (todayReviewCount == 9) {
            //     userStatus = "Incomplete Task";
            // }
            const response = await fetch('https://api.corpagent.global/api/user/' + curUserId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                // body: JSON.stringify({cashWallet: newBal, status: userStatus})
                body: JSON.stringify({status: userStatus})
            })
            const updateBal = await response.json();
            if (!response.ok) {
                setIsLoading(false);
                setError(updateBal.error);
            }
            if (response.ok) {
                setIsLoading(false);
            }
            if (todayReviewCount == taskList.length-1) {
                window.location.href = "/reviews";
            } else {
                window.location.href = "/single-review/"+taskList[todayReviewCount+1]._id;
            }
		}
    }

    return { submitReview, isLoading, error };
}