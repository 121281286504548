import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Hooks
import { usePropertyContext } from '../hooks/usePropertyContext';
import { useAuthContext } from '../hooks/useAuthContext';

const Bookings = () => {

  const {properties, dispatch} = usePropertyContext();
  const { user } = useAuthContext();

  useEffect(() => {
		const fetchProperties = async () => {
			const response = await fetch('https://api.corpagent.global/api/property/active', {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
			const json = await response.json();

			if (response.ok) {
				dispatch({type: 'GET_PROPERTIES', payload: json})
			}
		}

    if (user) {
      fetchProperties();
    }

	}, []);

  return (
    <>
      <div className="container">
        <div className="row mt-3 mb-5">
          <div className="col-12 mb-2">
						<h3 className="mb-0">All Booking Orders</h3>
            <p className="mb-0">{properties && properties.length} Total Listings</p>
					</div>
          {properties && properties.map((property, index) => (
            <>
              <div className="col-12 px-2 mt-2" key={property._id}>
                <div className="page-container">
                <div className="row">
                  <div className="col-lg-6">
                  <img className="w-100" src={property.coverImgLink} alt="Property Cover"></img>
                  </div>
                  <div className="col-lg-6 mt-2 mt-lg-0">
                  <p className="blue mb-2">
                    <span className="location-icon mr-2"><i className="fa-solid fa-location-dot"></i></span>
                    <strong>{property.location}</strong>
                  </p>
                  <h3 className="listing-title mb-0">{property.title}</h3>
                  <div>
                    <span className="review-stars"><i className="fa-solid fa-star"></i></span>
                    <span className="review-stars"><i className="fa-solid fa-star"></i></span>
                    <span className="review-stars"><i className="fa-solid fa-star"></i></span>
                    <span className="review-stars"><i className="fa-solid fa-star"></i></span>
                    <span className="review-stars"><i className="fa-solid fa-star"></i></span>
                  </div>
                  <h4 className="booking-price profit-green mb-0">${parseFloat(property.price).toFixed(2)}</h4>
                  <p className="mb-0">per night</p>
                  <Link to={'/property/' + property._id}>
                    <button className="btn action-button unselected-btn mt-2">
                    <p className="mb-0">Book Now</p>
                    </button>
                  </Link>
                  </div>
                </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );

};

export default Bookings;