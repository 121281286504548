import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import Moment from 'moment';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';
import { useOrder } from '../hooks/useOrder';

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);


const Property = () => {

  const { propertyID }  = useParams();

  const { user } = useAuthContext();

	const [propertyData, setPropertyData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [curOrderID, setCurOrderID] = useState();
  const [curOrderPeriod, setCurOrderPeriod] = useState();
  const [nextOrderCountdown, setNextOrderCountdown] = useState();

  const [amount, setAmount] = useState(0);

  const [roomType1, setRoomType1] = useState(false);
  const [roomType2, setRoomType2] = useState(false);
  const [roomType3, setRoomType3] = useState(false);
  const [roomType4, setRoomType4] = useState(false);

  const [roomMultiply1, setRoomMultiply1] = useState(1);
  const [roomMultiply2, setRoomMultiply2] = useState(1);
  const [roomMultiply3, setRoomMultiply3] = useState(1);
  const [roomMultiply4, setRoomMultiply4] = useState(1);

  const { placeOrder, generateNextOrder, error, isLoading, orderSuccess } = useOrder();

  const fetchOrderLogs = async () => {
    const response = await fetch('https://api.corpagent.global/api/orderLog/');
    const json = await response.json();
    setCurOrderID(json[0].orderID);
    setCurOrderPeriod((Moment(json[0].createdAt).unix()*1000)+180000);
    setNextOrderCountdown((Moment(json[0].createdAt).unix()*1000)+240000);
    if (Date.now() > (Moment(json[0].createdAt).unix()*1000)+240000) {
      console.log("Order expired. Generate new order.");
      await generateNextOrder(json[0].orderID);
    }
  }

  const orderTimeLimit = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <span><h6 className="mt-2 error">Order ID {"#101"+curOrderID} expired. Please wait for the next order...</h6></span>
          {nextOrderCountdown && nextOrderCountdown != null ? (
            <Countdown date={nextOrderCountdown} renderer={nextOrder}/>
          ):(
            <></>
          )}
        </>
      );
    } else {
      return (
        <>
          <div className="demand-container w-100">
            <h6 className="error mt-3 mb-2"><strong>High-demand Booking Order</strong></h6>
            <p className=""></p>
          </div>
          <form className="w-100" onSubmit={handleOrder}>
            <h6 className="mt-3">Order ID:</h6>
            <h3 className="">{"#101"+curOrderID}</h3>
            <h6 className="mt-3">Time Left:</h6>
            <h3><span>{zeroPad(minutes)}:{zeroPad(seconds)}</span></h3>
            <div className="mb-2">
              <label htmlFor="amount">
                <h6 className="mt-3 mb-0">Enter Order Bidding Amount:</h6>
              </label>
              <input
                type="number"
                step="0.01"
                placeholder='Amount'
                autoComplete='off'
                name='amount'
                className='form-control'
                onChange={handleAmountInput}
              />
              <p className={!balError ? "mt-0 mb-0" : "error mt-0 mb-0"}>Balance: ${parseFloat(userData.cashWallet).toFixed(2)}</p>
              {balError && <div className="error-box">{balError}</div>}
            </div>
            <h6 className="mt-3">Total Order Bidding:</h6>
            <p className="">${parseFloat(amount*roomMultiply1*roomMultiply2*roomMultiply3*roomMultiply4).toFixed(2)}</p>
            <button disabled={isLoading || amount == 0 || invalidAmount || roomMultiply1*roomMultiply2*roomMultiply3*roomMultiply4 <= 1} type="submit" className="btn action-button w-100" data-toggle="modal" data-target="#bookingConfirmationModal">
              <p className="mb-0"><strong>Confirm Order Bidding</strong></p>
            </button>
          </form>
        </>
      );
    }
  };

  const nextOrder = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <button className="btn action-button" onClick={(e) => {
            fetchOrderLogs();
          }}>
            <p className="mb-0"><strong><i className="fa-solid fa-rotate-right mr-2"></i>Refresh Order</strong></p>
          </button>
        </>
      );
    } else {
      return (
        <>
          <h2><span>{zeroPad(minutes)}:{zeroPad(seconds)}</span></h2>
        </>
      );
    }
  };


  const handleOrder = async (e) => {
		e.preventDefault();
		await placeOrder(
      curOrderID,
      user.token,
      userData._id,
      userData.cashWallet,
      userData.username,
      userData.status,
      roomType1,
      roomType2,
      roomType3,
      roomType4,
      amount,
      amount*roomMultiply1*roomMultiply2*roomMultiply3*roomMultiply4
    );
    getUserData();
	};

  const [balError, setBalError] = useState('');
  const [invalidAmount, setInvalidAmount] = useState(true);
  const handleAmountInput = (e) => {
		e.preventDefault();
    if (userData.cashWallet < e.target.value) {
      setInvalidAmount(true);
      setBalError('Insufficient fund. Please top up cash wallet.');
    } else {
      setInvalidAmount(false);
      setBalError('');
    }
    setAmount(e.target.value);
	};

  const fetchPropertyData = async () => {
    const response = await fetch('https://api.corpagent.global/api/property/' + propertyID, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();
    setPropertyData(json);
  }

  const getUserData = async () => {
    const response = await fetch('https://api.corpagent.global/api/user/'+ user.username, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();

    if (response.ok) {
      if (json.length === 1) {
        setUserData(json[0]);
        if (json[0].freezed) {
          localStorage.removeItem('corpagent_logged_in');
          window.location.href= "/";
        }
      } else {
        console.log("Invalid user data: More than 1 result.")
      }
    }
  }

  useEffect(() => {
    if (user) {
      fetchPropertyData();
      getUserData();
      fetchOrderLogs();
    }
	}, []);

  return (
    <>
      <div className="modal fade" id="bookingConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="bookingConfirmation" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-body">
              {orderSuccess ? (
                <div className="text-center">
                  <span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
                  <h5 className="">Order Bid Success<br></br>{"#101"+curOrderID}</h5>
                  <button className="btn action-button" onClick={(e) => {
                    window.location.href = "/orders";
                  }}>
                    <p className="mb-0"><strong>View Orders</strong></p>
                  </button>
                </div>
              ):(
                <div className="text-center">
                  <span className="error-icon"><i className="fa-solid fa-circle-xmark"></i></span>
                  <h5 className="">Order Bid Failed</h5>
                  {error && <div className="error-box">{error}</div>}
                  <p className="mt-3">Please contact support or try again.</p>
                  <button className="btn action-button" onClick={(e) => {
                    window.location.reload();
                  }}>
                    <p className="mb-0"><strong>Reload</strong></p>
                  </button>
                </div>
              )}

						</div>
					</div>
				</div>
			</div>

      <div className="container">
        <div className="row">
          <div className="col-12 p-0">
            <Swiper
              loop
              grabCursor={true}
              slidesPerView={1}
              speed={1000}
              pagination={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide className="text-center"><img className="hotel-slider-img" src={propertyData.coverImgLink} alt="Homepage Banner"></img></SwiperSlide>
            </Swiper>
          </div>
        </div>

        {/* Hotel Details */}
        <div className="row mt-2 mb-3">
          <div className="col-lg-8 px-2 mt-2">
            <div className="page-container">
              <h3 className="mt-3 mb-2">{propertyData.title}</h3>
              <p className="">
                <span className="location-icon mr-2"><i className="fa-solid fa-location-dot"></i></span>
                {propertyData.address}
              </p>
              <h3 className="blue mt-3 mb-0">{propertyData.star}.0 Rating</h3>
              <div>
                <span className={propertyData.star >= 1 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
                <span className={propertyData.star >= 2 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
                <span className={propertyData.star >= 3 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
                <span className={propertyData.star >= 4 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
                <span className={propertyData.star >= 5 ? "review-stars" : ""}><i className="fa-sharp fa-solid fa-star"></i></span>
							</div>
              <hr></hr>
              <div className="row px-2">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="mt-3 mb-2">Select Room</h5>
                    </div>
                    <div className="col-6 col-lg-3 px-2 mt-2">
                      <button className={roomType1 ? "btn w-100 selected-option-button" : "btn w-100 option-button"} onClick={(e) => {
                        setRoomType1(!roomType1);
                        if (roomType1) {
                          setRoomMultiply1(1);
                        } else {
                          setRoomMultiply1(2);
                        }
                      }}>
                        <p className={roomType1 ? "text-white mb-0" : "mb-0"}>Single Room</p>
                      </button>
                    </div>
                    <div className="col-6 col-lg-3 px-2 mt-2">
                      <button className={roomType2 ? "btn w-100 selected-option-button" : "btn w-100 option-button"} onClick={(e) => {
                        setRoomType2(!roomType2);
                        if (roomType2) {
                          setRoomMultiply2(1);
                        } else {
                          setRoomMultiply2(2);
                        }
                      }}>
                        <p className={roomType2 ? "text-white mb-0" : "mb-0"}>Queen Room</p>
                      </button>
                    </div>
                    <div className="col-6 col-lg-3 px-2 mt-2">
                      <button className={roomType3 ? "btn w-100 selected-option-button" : "btn w-100 option-button"} onClick={(e) => {
                        setRoomType3(!roomType3);
                        if (roomType3) {
                          setRoomMultiply3(1);
                        } else {
                          setRoomMultiply3(2);
                        }
                      }}>
                        <p className={roomType3 ? "text-white mb-0" : "mb-0"}>King Room</p>
                      </button>
                    </div>
                    <div className="col-6 col-lg-3 px-2 mt-2">
                      <button className={roomType4 ? "btn w-100 selected-option-button" : "btn w-100 option-button"} onClick={(e) => {
                        setRoomType4(!roomType4);
                        if (roomType4) {
                          setRoomMultiply4(1);
                        } else {
                          setRoomMultiply4(2);
                        }
                      }}>
                        <p className={roomType4 ? "text-white mb-0" : "mb-0"}>Deluxe Room</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-2 mt-2">
                  <div className="page-container mt-2">
                      {curOrderPeriod && curOrderPeriod != null ? (
                        <Countdown date={curOrderPeriod} renderer={orderTimeLimit}/>
                      ):(
                        <></>
                      )}
                  </div>
                </div>
              </div>
            </div>
					</div>
          <div className="col-lg-4 px-2">
            <div className="page-container mt-2">
              <h5 className="mt-3 mb-2">Facilities</h5>
              <ul>
                <li>Front desk (24-hour)</li>
                <li>Airport transfer</li>
                <li>Car park</li>
                <li>Shuttle service</li>
                <li>Free Wi-Fi</li>
                <li>Express Check-in/out</li>
                <li>Luggage Storage</li>
                <li>International Restaurant</li>
              </ul>
            </div>
					</div>
        </div>
      </div>
    </>
  );

};

export default Property;