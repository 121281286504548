import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { useAuthContext } from '../hooks/useAuthContext';

const Orders = () => {

  const { user } = useAuthContext();
  const [ordersData, setOrdersData] = useState([]);

  useEffect(() => {

		const fetchOrdersData = async () => {
			const response = await fetch('https://api.corpagent.global/api/order/user/' + user.username, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
			const json = await response.json();
      console.log(json);
      setOrdersData(json);
		}

    if (user) {
      fetchOrdersData();
    }

	}, []);

  return (
    <>
      <div className="container">
        {/* Order History */}
        <div className="row mt-3 mb-5">
          <div className="col-12">
						<h3 className="mb-0">Order Bidding History</h3>
					</div>
					<div className="col-12 mt-3">
            {ordersData && ordersData.length > 0 ? (
              <table className="table table-hover table-striped">
                <thead className="thead-dark">
                  <tr className="">
                    <th className="p-3" scope="col"><p className="mb-0">Order Bidding Details</p></th>
                  </tr>
                </thead>
                <tbody>
                {ordersData && ordersData.map((orders, index) => (
                  <tr key={index}>
                    <td className="px-3 py-2">
                      <p className="mb-0"><strong>Order</strong> #{ordersData[index].orderID}</p>
                      <p className="mb-0">
                        {ordersData[index].status === "Matching" ? <strong>Status: <span className="vip-yellow">{ordersData[index].status}</span></strong> : null}
                        {ordersData[index].status === "Matched" ? <strong>Status: <span className="profit-green">{ordersData[index].status}</span></strong> : null}
                        {ordersData[index].status === "Cancelled" ? <strong>Status: <span className="error">{ordersData[index].status}</span></strong> : null}
                        {ordersData[index].status === "Error" ? <strong>Status: <span className="error">{ordersData[index].status}</span></strong> : null}
                      </p>
                      <p className="mb-0">
                        <strong>Room Type: </strong>
                        <span>
                          {ordersData[index].item1 && "•Single "}
                          {ordersData[index].item2 && "•Queen "}
                          {ordersData[index].item3 && "•King "}
                          {ordersData[index].item4 && "•Deluxe "}
                        </span>
                      </p>
                      <p className="mb-0"><strong>Order Amount: </strong>${parseFloat(ordersData[index].price).toFixed(2)}</p>
                      <p className="mb-0"><strong>Total Bidding Amount: </strong>${parseFloat(ordersData[index].totalAmount).toFixed(2)}</p>
                      {ordersData[index].commission && ordersData[index].commission > 0 ? (
                        <p className="mb-0"><strong>Commission Earned: <span className="profit-green">+${parseFloat(ordersData[index].commission).toFixed(2)}</span></strong></p>
                      ):(
                        <p className="mb-0"><strong>Commission Earned: </strong>$0.00</p>
                      )}
                      <p className="mb-0"><strong>Date & Time: </strong>{Moment(ordersData[index].createdAt).format('YYYY-MM-DD HH:mm')}</p>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            ):(
              <p className="">No order bidding history found.</p>
            )}
					</div>
        </div>
      </div>
    </>
  );

};

export default Orders;