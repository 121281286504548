// Dependencies
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, menuClasses } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';

// Hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';

// Components
import ContactUsIcon from "./components/contact-us-icon";

// Pages
import Home from "./pages/home";
import AllReviews from "./pages/all-reviews";
import SingleReview from "./pages/single-review";
import Bookings from "./pages/bookings";
import StarScore from "./pages/star-score";
import Property from "./pages/property";
import Profile from "./pages/profile";
import Orders from "./pages/orders";
import Deposit from "./pages/deposit";
import Withdraw from "./pages/withdraw";
import Login from "./pages/login";
import Signup from "./pages/signup";

import SquareLogo from './assets/img/logo-square.png';
import Logotext from './assets/img/logotext.png';


function App() {

  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
    window.location.href= "/";
  }

	const [userData, setUserData] = useState();
  const getUserData = async () => {
		const response = await fetch('https://api.corpagent.global/api/user/'+ user.username, {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				console.log(json[0]);
				setUserData(json[0]);
				if (json[0].freezed) {
					localStorage.removeItem('corpagent_logged_in');
					window.location.href= "/";
				  }
			} else {
				console.log("Invalid user data: More than 1 result.")
			}
		}
	}

  const { user } = useAuthContext();

  return (
    <Router>
      <ContactUsIcon/>
      {user == null ? (
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>
        </Routes>
      ):(
        <>
          {user.freezed ? (
            <>
              <div className="container form-page-padding" style={{marginTop: "80px"}}>
                <div className="row">
                  <div className="col-12">
                    <div className="error-box py-3">
                      <h6 className="error mb-0">Account Suspended</h6>
                      <p className="mb-0">This account has violated our Terms & Conditions.</p>
                    </div>
                    <button className="btn action-button remove-btn mt-2" onClick={(e) => {
                      handleLogout();
                    }}>
                      <p className="mb-0">Logout<i className="fa-solid fa-right-from-bracket ml-2"></i></p>
                    </button>
                  </div>
                </div>
              </div>
            </>
          ):(
            <>
              {/* Bottom Menu */}
              <section className="bottom-menu">
                <div className="container">
                  <div className="row">
                    <div className="col-3 px-1">
                      <NavLink to="/">
                        <button className="btn w-100" >
                          <span className="bottom-menu-icon text-gray">
                            <i className="fa-solid fa-house"></i>
                          </span>
                          <p className="bottom-menu-text text-gray mb-0">Home</p>
                        </button>
                      </NavLink>
                    </div>
                    <div className="col-3 px-1">
                      <NavLink to="/reviews">
                        <button className="btn w-100">
                          <span className="bottom-menu-icon text-gray">
                            <i className="fa-sharp fa-solid fa-star-half-stroke"></i>
                          </span>
                          <p className="bottom-menu-text text-gray mb-0">Reviews</p>
                        </button>
                      </NavLink>
                    </div>
                    <div className="col-3 px-1">
                      <NavLink to="/bookings">
                        <button className="btn w-100">
                          <span className="bottom-menu-icon text-gray">
                            <i className="fa-sharp fa-solid fa-hotel"></i>
                          </span>
                          <p className="bottom-menu-text text-gray mb-0">Bookings</p>
                        </button>
                      </NavLink>
                    </div>
                    <div className="col-3 px-1">
                      <button className="btn w-100" onClick={() => 
                        setToggled(!toggled)
                      }>
                        <span className="bottom-menu-icon text-gray">
                          <i className="fa-sharp fa-solid fa-bars"></i>
                        </span>
                        <p className="bottom-menu-text text-gray mb-0">More</p>
                      </button>
                    </div>
                  </div>
                </div>
              </section>

              <div className="d-flex">
                <Sidebar 
                  collapsed={collapsed}
                  transitionDuration={500}
                  width="260px"
                  backgroundColor="#0C0C0C"
                  breakPoint="all"
                  rtl="true"
                  onBackdropClick={() => setToggled(false)}
                  toggled={toggled}
                  rootStyles={{
                    height: '100vh',
                    zIndex: '110',
                    border: 'none'
                  }}
                >

                  <NavLink to="/">
                    <div className="side-menu-brand-area">
                      {!collapsed ? (
                        <img className="side-menu-logotext" src={Logotext} alt="logo"></img>
                      ):(
                        <img className="side-menu-logo" src={SquareLogo} alt="logo"></img>
                      )}
                    </div>
                  </NavLink>

                  <Menu
                    renderExpandIcon={({ open }) => 
                      <span className="collapse-icon">
                        {open ? <i className="fa-duotone fa-caret-down mt-2"></i> : <i className="fa-duotone fa-caret-left mt-2"></i>}
                      </span>
                    }
                    rootStyles={{
                      ['.' + menuClasses.button]: {
                        backgroundColor: '#0C0C0C',
                        color: '#DCDCDC',
                        '&:hover': {
                          backgroundColor: '#2891e7',
                        },
                      },
                      ['.active']: {
                        backgroundColor: '#004AAD',
                      }
                    }}
                  > 

                    <MenuItem 
                      icon={
                        <span className="side-menu-icon">
                          <i className={!collapsed ? "fa-regular fa-angles-right" : "fa-regular fa-angles-left"}></i>
                        </span>
                      }
                      onClick={() => setCollapsed(!collapsed)}
                    > 
                      <p className="side-menu-text"><strong>Collapse Menu</strong></p>
                    </MenuItem>

                    <hr className="sidebar-line"></hr>

                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-sharp fa-solid fa-hotel"></i></span>}
                      component={<NavLink to="/bookings" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">Bookings</p>
                    </MenuItem>
                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-sharp fa-solid fa-star-half-stroke"></i></span>}
                      component={<NavLink to="/reviews" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">Reviews</p>
                    </MenuItem>

                    <hr className="sidebar-line"></hr>

                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-solid fa-user"></i></span>}
                      component={<NavLink to="/profile" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">My Profile</p>
                    </MenuItem>

                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-regular fa-clipboard-list"></i></span>}
                      component={<NavLink to="/orders" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">Order History</p>
                    </MenuItem>

                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-solid fa-stars"></i></span>}
                      component={<NavLink to="/star-score" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">Star Score</p>
                    </MenuItem>

                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-solid fa-hand-holding-circle-dollar"></i></span>}
                      component={<NavLink to="/deposit" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">Deposit</p>
                    </MenuItem>

                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-solid fa-money-bill-transfer"></i></span>}
                      component={<NavLink to="/withdraw" activeClassName={['menu-item'].active}/>}
                    > 
                      <p className="side-menu-text">Withdraw</p>
                    </MenuItem>

                    <hr className="sidebar-line"></hr>

                    <MenuItem 
                      icon={<span className="side-menu-icon"><i className="fa-duotone fa-headset"></i></span>}
                      onClick={() => window.open('https://t.me/KinkiCAG', '_blank')}
                    > 
                      <p className="side-menu-text">Contact Us</p>
                    </MenuItem>

                    <MenuItem
                      className="menu-item"
                      icon={<span className="side-menu-icon"><i className="fa-duotone fa-globe"></i></span>}
                      onClick={() => window.location.replace('https://corpagent.asia')}
                    > 
                      <p className="side-menu-text">About Us</p>
                    </MenuItem>

                    <MenuItem
                      className="menu-item"
                      icon={<span className="side-menu-icon"><i className="fa-solid fa-right-from-bracket"></i></span>}
                      onClick={handleLogout}
                    > 
                      <p className="side-menu-text">Logout</p>
                    </MenuItem>
                  </Menu>

                </Sidebar>

                <main className="main-content">
                  <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/reviews" element={<AllReviews/>}/>
                    <Route path="/single-review/:propertyID" element={<SingleReview/>}/>
                    <Route path="/bookings" element={<Bookings/>}/>
                    <Route path="/star-score" element={<StarScore/>}/>
                    <Route path="/property/:propertyID" element={<Property/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/orders" element={<Orders/>}/>
                    <Route path="/deposit" element={<Deposit/>}/>
                    <Route path="/withdraw" element={<Withdraw/>}/>
                  </Routes>
                </main>
              </div>
            </>
          )}
        </>
      )}
      
    </Router>
  );
}

export default App;