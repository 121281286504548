import { useState } from 'react';

export const useDeposit = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const submitDeposit = async (authToken, curUsername, depositAmount) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.corpagent.global/api/deposit/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                username: curUsername,
                amount: depositAmount,
                processBy: "",
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            setIsLoading(false);
        }
    }

    return { submitDeposit, isLoading, error };
}