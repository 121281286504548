import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';
import { usePropertyContext } from '../hooks/usePropertyContext';

// Images
import HomeBanner from "../assets/img/banner/home-banner.jpg";


const Home = () => {
	
	const { user } = useAuthContext();
	const {properties, dispatch} = usePropertyContext();
	const [userdata, setUserData] = useState([]);

	useEffect(() => {
		const fetchProperties = async () => {
			const response = await fetch('https://api.corpagent.global/api/property/active', {
				headers: {
				'Authorization': `Bearer ${user.token}`
				}
			});
			const json = await response.json();

			if (response.ok) {
				dispatch({type: 'GET_PROPERTIES', payload: json})
			}
		}


		const getUserData = async () => {
			const response = await fetch('https://api.corpagent.global/api/user/'+ user.username, {
				headers: {
					'Authorization': `Bearer ${user.token}`
				}
			});
			const json = await response.json();

			if (response.ok) {
				if (json.length === 1) {
					setUserData(json[0]);
					if (json[0].freezed) {
						localStorage.removeItem('corpagent_logged_in');
						window.location.href= "/";
					}
				} else {
					console.log("Invalid user data: More than 1 result.")
				}
			}
		}

		if (user) {
			getUserData();
			fetchProperties();
		}

	}, []);

  	return (
		<>	
			<div className="container">
				{/* Page Banner */}
				<div className="row">
					<div className="col-12 p-0">
					<img class="page-banner" src={HomeBanner} alt="Homepage Banner"></img>
					</div>
				</div>
			</div>
			{userdata != null ? (
			<div className="container">
			<div className="row mt-3">
				<div className="col-12">
					<h3 className="mb-0">Welcome, {userdata.username}</h3>
				</div>
				<div className="col-12 px-2 mt-2">
					<div className="page-container">
						<div className="row">
							<div className="col-3 text-center px-0">
								<Link to="/profile">
									<button className="btn py-0 w-100">
										<span className="profile-menu-icon">
											<i className="fa-solid fa-user"></i>
										</span>
										<p className="profile-menu-text mb-0">Profile</p>
									</button>
								</Link>
							</div>
							<div className="col-3 text-center px-0">
								<Link to="/orders">
									<button className="btn py-0 w-100">
										<span className="profile-menu-icon">
											<i className="fa-regular fa-clipboard-list"></i>
										</span>
										<p className="profile-menu-text mb-0">Orders</p>
									</button>
								</Link>
							</div>
							<div className="col-3 text-center px-0">
								<Link to="/deposit">
									<button className="btn py-0 w-100">
										<span className="profile-menu-icon">
											<i className="fa-solid fa-hand-holding-circle-dollar"></i>
										</span>
										<p className="profile-menu-text mb-0">Deposit</p>
									</button>
								</Link>
							</div>
							<div className="col-3 text-center px-0">
								<Link to="/withdraw">
									<button className="btn py-0 w-100">
										<span className="profile-menu-icon">
											<i className="fa-solid fa-money-bill-transfer"></i>
										</span>
										<p className="profile-menu-text mb-0">Withdraw</p>
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 px-2 mt-2">
					<div className="data-box d-flex justify-contain-left">
						<div className="data-icon-box bg-dark-green">
							<span className="data-box-icon text-gray">
								<i className="fa-solid fa-wallet"></i>
							</span>
						</div>
						<div className="data-text-box">
							<h6 className="data-box-heading mb-0">Cash Wallet</h6>
							<p className="data-box-number profit-green mb-0">${parseFloat(userdata.cashWallet).toFixed(2)}<span className="text-dark"></span></p>
						</div>
					</div>
				</div>
				<div className="col-12 px-2 mt-2">
					<div className="data-box d-flex justify-contain-left">
						<div className="data-icon-box bg-yellow">
							<span className="data-box-icon text-gray">
								<i className="fa-solid fa-stars"></i>
							</span>
						</div>
						<div className="data-text-box">
							<h6 className="data-box-heading mb-0">Star Scoring</h6>
							<p className="data-box-number mb-0">{parseFloat(userdata.points).toFixed(2)}</p>
						</div>
					</div>
				</div>
			</div>
		

			{/* Booking Listing */}
			<div className="row mt-3 mb-5">
				<div className="col-12 mt-3">
					<h3 className="mb-0">Popular Booking Orders</h3>
				</div>
				{properties && properties.map((property, index) => (
					<>
						<div className="col-12 px-2 mt-2" key={property._id}>
							<div className="page-container">
							<div className="row">
								<div className="col-lg-6">
								<img className="w-100" src={property.coverImgLink} alt="Property Cover"></img>
								</div>
								<div className="col-lg-6 mt-2 mt-lg-0">
								<p className="blue mb-2">
									<span className="location-icon mr-2"><i className="fa-solid fa-location-dot"></i></span>
									<strong>{property.location}</strong>
								</p>
								<h3 className="listing-title mb-0">{property.title}</h3>
								<div>
									<span className="review-stars"><i className="fa-solid fa-star"></i></span>
									<span className="review-stars"><i className="fa-solid fa-star"></i></span>
									<span className="review-stars"><i className="fa-solid fa-star"></i></span>
									<span className="review-stars"><i className="fa-solid fa-star"></i></span>
									<span className="review-stars"><i className="fa-solid fa-star"></i></span>
								</div>
								<h4 className="booking-price profit-green mb-0">${parseFloat(property.price).toFixed(2)}</h4>
								<p className="mb-0">per night</p>
								<Link to={'/property/' + property._id}>
									<button className="btn action-button unselected-btn mt-2">
									<p className="mb-0">Book Now</p>
									</button>
								</Link>
								</div>
							</div>
							</div>
						</div>
					</>
				))}
			</div>

		</div>
			):(
				<></>
			)}


		</>
  );

};

export default Home;