import { useState } from 'react';

export const useSignup = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const signup = async (username, fullname, email, phone, password) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.corpagent.global/api/user/signup', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
                username,
                fullname,
                referrer: "master",
                email,
                phone,
                password,
                securityPassword: '',
                rank: "Member",
                cashWallet: 0,
                points: 500,
                bankName: '',
                accNumber: '', 
                accName: '',
                status: "Disabled"
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            // save the user to local storage
            // localStorage.setItem('corpagent_logged_in', JSON.stringify(json));

            // update the auth context
            // dispatch({type: 'LOGIN', payload: json});

            setIsLoading(false);

            window.location.href = "/";

		}
    }

    return { signup, isLoading, error };
}