import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { useAuthContext } from '../hooks/useAuthContext';
import { useWithdrawal } from '../hooks/useWithdrawal';

const Withdraw = () => {

	const { user } = useAuthContext();
	const [userData, setUserData] = useState();
	const [withdrawalData, setWithdrawalData] = useState();
	const [amount, setAmount] = useState();

	const [bankName, setBankName] = useState('');
	const [accountNumber, setAccountNumber] = useState('');
	const [accountName, setAccountName] = useState('');

	const {submitWithdraw, isLoading, error} = useWithdrawal();
	const [submitted, setSubmitted] = useState(false);

	const handleWithdraw = async (e) => {
		e.preventDefault();
		// if (bankName !== '' || accountNumber !== '' || accountName !== '') {
		// 	await submitWithdraw(user.token, userData._id, withdrawalData.length, user.username, amount, bankName, accountNumber, accountName, userData.cashWallet, false);
		// } else {
		// 	await submitWithdraw(user.token, userData._id, withdrawalData.length, user.username, amount, userData.bankName, userData.accNumber, userData.accName, userData.cashWallet, true);
		// }
		if (accountNumber !== '') {
			await submitWithdraw(user.token, userData._id, withdrawalData.length, user.username, amount, accountNumber, userData.cashWallet, false);
		} else {
			await submitWithdraw(user.token, userData._id, withdrawalData.length, user.username, amount, userData.accNumber, userData.cashWallet, true);
		}
		fetchWithdrawalData();
		getUserData();
		setSubmitted(true);
	};

	const fetchWithdrawalData = async () => {
		const response = await fetch('https://api.corpagent.global/api/withdrawal/user/' + user.username, {
			headers: {
			'Authorization': `Bearer ${user.token}`
			}
		});
		const withdrawalRecords = await response.json();
		setWithdrawalData(withdrawalRecords);
	}

	const getUserData = async () => {
		const response = await fetch('https://api.corpagent.global/api/user/'+ user.username, {
			headers: {
				'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			if (json.length === 1) {
				// Expect only 1 result
				console.log(json[0]);
				setUserData(json[0]);
				if (json[0].freezed) {
					localStorage.removeItem('corpagent_logged_in');
					window.location.href= "/";
				  }
			} else {
				console.log("Invalid user data: More than 1 result.")
			}
		}
	}

	useEffect(() => {

		if (user) {
			getUserData();
			fetchWithdrawalData();
		}

	}, []);

	return (
		<>
			{userData ? (
				<>
					{/* Withdrawal Modal */}
					<div className="modal fade" id="withdrawalModal" tabindex="-1" role="dialog" aria-labelledby="withdrawalModal" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="exampleModalLongTitle">Withdrawal</h5>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
									</button>
								</div>
								{!submitted ? (
									<div className="modal-body">
										<form className="w-100" onSubmit={handleWithdraw}>
											{/* {userData.bankName === '' || userData.accNumber === '' || userData.accName === '' ? (
												<>
													<div className="mb-2">
														<label htmlFor="bankName">
															<strong>Bank Name</strong>
														</label>
														<input
															type="text"
															placeholder='Enter bank name'
															autoComplete='off'
															name='bankName'
															className='form-control'
															onChange={(e) => setBankName(e.target.value)}
														/>
													</div>
													<div className="mb-2">
														<label htmlFor="accountNumber">
															<strong>Account Number</strong>
														</label>
														<input
															type="text"
															placeholder='Enter account number'
															autoComplete='off'
															name='accountNumber'
															className='form-control'
															onChange={(e) => setAccountNumber(e.target.value)}
														/>
													</div>
													<div className="mb-2">
														<label htmlFor="accountName">
															<strong>Account Name</strong>
														</label>
														<input
															type="text"
															placeholder='Enter account name'
															autoComplete='off'
															name='accountName'
															className='form-control'
															onChange={(e) => setAccountName(e.target.value)}
														/>
													</div>
												</>
											):(
												<></>
											)}		 */}
											{userData.accNumber === '' ? (
												<>
													<div className="mb-2">
														<label htmlFor="accountNumber">
															<strong>Wallet Address (TRC20):</strong>
														</label>
														<input
															type="text"
															placeholder='Enter TRC20 Wallet Address'
															autoComplete='off'
															name='accountNumber'
															className='form-control'
															onChange={(e) => setAccountNumber(e.target.value)}
														/>
													</div>
												</>
											):(
												<></>
											)}	
											<div className="mb-2">
												<label htmlFor="amount">
													<strong>Withdraw Amount:</strong>
												</label>
												<input
													type="number"
													step="0.01"
													placeholder='Enter withdrawal amount'
													autoComplete='off'
													name='amount'
													className='form-control'
													onChange={(e) => setAmount(e.target.value)}
												/>
											</div>
											<button disabled={isLoading} type="submit" className="btn action-button w-100">
												<p className="text-white mb-0">Confirm Withdraw</p>
											</button>
											{error && <div className="error">{error}</div>}
										</form>
									</div>
								):(
									<div className="modal-body">
										<div className="text-center">
											<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
											<h5 className="">Withdrawal Submitted</h5>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="container">
						<div className="row mt-3 mb-5">
							<div className="col-12">
								<h3 className="mb-0">Withdraw</h3>
							</div>
							<div className="col-12 mt-2">
								<div className="data-box d-flex justify-contain-left">
									<div className="data-icon-box bg-dark-green">
										<span className="data-box-icon text-gray">
											<i className="fa-solid fa-wallet"></i>
										</span>
									</div>
									<div className="data-text-box">
										<h6 className="data-box-heading mb-0">Cash Wallet</h6>
										<p className="data-box-number profit-green mb-0">${parseFloat(userData.cashWallet).toFixed(2)} <span className="text-dark"></span></p>
									</div>
								</div>
								{userData.cashWallet > 0 ? (
									<button className="btn action-button unselected-btn mt-2 w-100" data-toggle="modal" data-target="#withdrawalModal" onClick={(e) => {
										setSubmitted(false);
									}}>
										<p className="mb-0">Withdraw</p>
									</button>
								):(
									<button className="btn action-button remove-btn mt-2 w-100">
										<p className="mb-0">Nothing to withdraw</p>
									</button>
								)}

							</div>
						</div>

						{/* Withdrawal Records */}
						<div className="row mt-3 mb-5">
							<div className="col-12">
								<h3 className="mb-0">Withdrawal History</h3>
							</div>
							<div className="col-12 mt-3">
								{withdrawalData && withdrawalData.length > 0 ? (
									<table className="table table-hover table-striped">
										<thead className="thead-dark">
											<tr className="">
												<th className="p-3" scope="col"><p className="mb-0">Withdrawal Details</p></th>
											</tr>
										</thead>
										<tbody>
										{withdrawalData && withdrawalData.map((withdrawals, index) => (
											<tr key={index}>
											<td className="px-3 py-2">
											<p className="mb-0"><strong>Withdraw ID:</strong> #{withdrawalData[index].withdrawID}</p>
											<p className="mb-0"><strong>Amount: </strong>${parseFloat(withdrawalData[index].amount).toFixed(2)} <span className="text-dark"></span></p>
											{withdrawalData[index].status === "Processing" ? <p className="mb-0"><strong>Status: <span className="vip-yellow">{withdrawalData[index].status}</span></strong></p> : null}
											{withdrawalData[index].status === "Approved" ? <p className="mb-0"><strong>Status: <span className="profit-green">{withdrawalData[index].status}</span></strong></p> : null}
											{withdrawalData[index].status === "Rejected" ? <p className="mb-0"><strong>Status: <span className="error">{withdrawalData[index].status}</span></strong></p> : null}
											{/* <p className="mb-0"><strong>Bank: </strong>{withdrawalData[index].bankName}</p> */}
											<p className="mb-0"><strong>Wallet Address (TRC20): </strong>{withdrawalData[index].accNumber}</p>
											{/* <p className="mb-0"><strong>Acc. Name: </strong>{withdrawalData[index].accName}</p> */}
											<p className="mb-0"><strong>Date & Time: </strong>{Moment(withdrawalData[index].createdAt).format('YYYY-MM-DD HH:mm')}</p>
											<p className="mb-0"><strong>Remarks: </strong><br></br>{withdrawalData[index].remarks}</p>
											</td>
											</tr>
										))}
										</tbody>
									</table>
								):(
					<p className="">No withdrawal history found.</p>
					)}
							</div>
						</div>
					</div>
				</>
			):(
				<></>
			)}
		</>
	);

};

export default Withdraw;